/* global gtag */

import React, { useState } from 'react';
import './Calculator.css';

const Calculator = () => {
  const [volume, setVolume] = useState('');
  const [percentage, setPercentage] = useState('');
  const [price, setPrice] = useState('');
  const [efficiency, setEfficiency] = useState(null);
  const [savedResults, setSavedResults] = useState(() => {
    const saved = localStorage.getItem('palResults');
    return saved ? JSON.parse(saved) : [];
  });
  const [showResults, setShowResults] = useState(false);

  const normalizeInput = (value) => {
    return value.replace(',', '.');
  };

  const calculateEfficiency = () => {
    const volumeFloat = parseFloat(normalizeInput(volume));
    const percentageFloat = parseFloat(normalizeInput(percentage));
    const priceFloat = parseFloat(normalizeInput(price));
    if (isNaN(volumeFloat) || isNaN(percentageFloat) || isNaN(priceFloat)) {
      setEfficiency(null);
      return;
    }
    const pal = (((volumeFloat * percentageFloat) / priceFloat) * 10) * 1.3;
    setEfficiency(pal.toFixed(2));
    gtag('event', 'calculate_efficiency', {
      'event_category': 'interaction',
      'event_label': 'Calculate Efficiency Button Click',
      'value': pal.toFixed(2)
    });
  };

  const saveResult = () => {
    const drinkName = prompt('Name des Getränks:');
    if (!drinkName) return;
    const newResult = { name: drinkName, volume, percentage, price, efficiency };
    const updatedResults = [...savedResults, newResult].sort((a, b) => b.efficiency - a.efficiency);
    setSavedResults(updatedResults);
    localStorage.setItem('palResults', JSON.stringify(updatedResults));
    setShowResults(true);
    gtag('event', 'save_result', {
      'event_category': 'interaction',
      'event_label': 'Save Result Button Click',
      'drink_name': drinkName,
      'efficiency': efficiency
    });
  };

  const resetResults = () => {
    setSavedResults([]);
    localStorage.removeItem('palResults');
    setShowResults(false);
    gtag('event', 'reset_results', {
      'event_category': 'interaction',
      'event_label': 'Reset Results Button Click'
    });
  };

  return (
    <div className="calculator-container">
      <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="Palometer Logo" className="logo" />
      <h1>Palometer</h1>
      <p className="subtitle">Das Verhältnis von Preis, Alkohol und Leistung</p>
      <hr className="divider" />
      <table className="input-table">
        <tr className="input-group volume-group">
          <td className="input-cell label-cell"><label className="input-label">Menge in Liter:</label></td>
          <td className="input-cell"><input
            type="text"
            inputMode="decimal"
            value={volume}
            onChange={(e) => {
              setVolume(e.target.value);
              gtag('event', 'input_change', {
                'event_category': 'interaction',
                'event_label': 'Volume Input Change',
                'value': e.target.value
              });
            }}
            placeholder="0,5"
            className="input-field volume-input"
          /></td>
          <td className="input-cell symbol-cell"><span className="input-symbol">L</span></td>
        </tr>
        <tr className="input-group percentage-group">
          <td className="input-cell label-cell"><label className="input-label">Alkoholgehalt in %:</label></td>
          <td className="input-cell"><input
            type="text"
            inputMode="decimal"
            value={percentage}
            onChange={(e) => {
              setPercentage(e.target.value);
              gtag('event', 'input_change', {
                'event_category': 'interaction',
                'event_label': 'Percentage Input Change',
                'value': e.target.value
              });
            }}
            placeholder="5,2"
            className="input-field percentage-input"
          /></td>
          <td className="input-cell symbol-cell"><span className="input-symbol">%</span></td>
        </tr>
        <tr className="input-group price-group">
          <td className="input-cell label-cell"><label className="input-label">Preis in Euro:</label></td>
          <td className="input-cell"><input
            type="text"
            inputMode="decimal"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              gtag('event', 'input_change', {
                'event_category': 'interaction',
                'event_label': 'Price Input Change',
                'value': e.target.value
              });
            }}
            placeholder="1,29"
            className="input-field price-input"
          /></td>
          <td className="input-cell symbol-cell"><span className="input-symbol">€</span></td>
        </tr>
      </table>
      <button onClick={calculateEfficiency}>Effizienz palomieren</button>
      {efficiency !== null && (
        <div className="result">
          <p>Dein Getränk hat eine Effizienz von:</p>
          <p className="efficiency-value">{efficiency} pal</p>
          <div className="slider-container">
            <input
              type="range"
              min="0"
              max="100"
              value={efficiency}
              readOnly
              className="slider"
            />
          </div>
          <button onClick={saveResult} className="save-button">Ergebnis temporär speichern</button>
        </div>
      )}
      {showResults && (
        <div className="saved-results">
          <hr className="divider" />
          <h5>Zwischengespeicherte Werte
            <button onClick={resetResults} className="reset-button">Werte zurücksetzen</button>
          </h5>
          <table className="results-table">
            <thead>
              <tr>
                <th className="headTable">Name</th>
                <th className="headTable">L</th>
                <th className="headTable">%</th>
                <th className="headTable">€</th>
                <th className="headTable">Effizienz</th>
              </tr>
            </thead>
            <tbody>
              {savedResults.map((result, index) => (
                <tr key={index}>
                  <td className="result-name">{result.name}</td>
                  <td className="result-value">{result.volume}</td>
                  <td className="result-value">{result.percentage}</td>
                  <td className="result-value">{result.price}</td>
                  <td className="result-efficiency">{result.efficiency} pal</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <p className="tip">
        Tipp: Füge diese Seite zu deinen Lesezeichen hinzu, <br />
        um sie in den richtigen Momenten schnell zu finden
      </p>
    </div>
  );
};

export default Calculator;